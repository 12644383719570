<template>
  <div class="vap-page">
    <Loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div class="vap-page-header">
      <h1>
        <span v-if="profileName" style="color: black;">{{ profileName }}</span>
        <span v-else>Untitled Profile</span>
      </h1>
      <div class="btns-container">
        <VasionButton :classProp="'primary'" :isDisabled="!isDirty" @vasionButtonClicked="save()">
          Save
        </VasionButton>
        <VasionButton class="last-btn" :classProp="'secondary'" @vasionButtonClicked="cancel()">
          Cancel
        </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <!-- TABS -->
      <div class="row">
        <VasionButton
          id="tab-general"
          :classProp="calculatedClass('general')"
          :isInErrorState="tabSectionNeedsInput"
          @vasionButtonClicked="updateSelectedTab('general')"
        >
          GENERAL
        </VasionButton>
        <VasionButton
          id="tab-permissions"
          :classProp="calculatedClass('permissions')"
          @vasionButtonClicked="updateSelectedTab('permissions')"
        >
          PERMISSIONS
        </VasionButton>
      </div>
      <!-- END OF TABS -->

      <!-- GENERAL TAB -->
      <div v-if="selectedTabName === 'general'" class="main-body">
        <div class="row">
          <VasionInput
            id="name-input"
            v-model="profileName"
            class="row-item"
            :required="true"
            :isInErrorState="profileNameNeedsInput"
            inputType="top-white"
            placeholder="Enter Name..."
            title="PROFILE NAME"
            @input="removeErrorState('profile-name')"
          />
          <div class="row-item row-item-browse">
            <VasionInput
              id="destination-folder"
              v-model="selectedDestinationFolder.name"
              title="DESTINATION FOLDER"
              inputType="top-white"
              name="destination-folder"
              placeholder="Select Folder..."
              :isInErrorState="destinationFolderNeedsInput"
              :isDisabled="true"
              :width="'280'"
            />
            <VasionButton
              id="destination-folder-browse-button"
              class="browse-button"
              :classProp="'primary'"
              @vasionButtonClicked="toggleBrowseFolderDialog('destination-folder')"
            >
              Browse
            </VasionButton>
          </div>
          <VasionDropList
            v-slot="slotItem"
            v-model="selectedWorkflow"
            class="row-item"
            :dataArray="workflowListValues"
            title="WORKFLOW"
            placeholder="Select Workflow..."
            type="plain-list"
            displayName="name"
            valueName="value"
            :isInErrorState="workflowNeedsInput"
            @input="setSelectedWorkflow"
          >
            {{ slotItem.item.name }}
          </VasionDropList>
          <VasionDropList
            v-slot="slotItem"
            v-model="selectedObject"
            class="row-item"
            :dataArray="objectList"
            :title="`${$formsLabel.toUpperCase()}`"
            :placeholder="`Select ${$formsLabel}...`"
            type="plain-list"
            displayName="name"
            valueName="value"
            @input="setSelectedObject"
          >
            {{ slotItem.item.name }}
          </VasionDropList>
        </div>

        <div class="row">
          <VasionCheckbox
            id="prompt-for-filling-out-fields-checkbox"
            name="prompt-for-filling-out-fields-checkbox"
            class="row-item"
            :checked="promptForFillingOutFields"
            @change="toggleCheckbox('prompt-for-filling-out-fields')"
          >
            Prompt for Filling Out Fields
          </VasionCheckbox>
        </div>

        <div id="scan-settings" class="collapsable-section">
          <VasionButton
            name="collapse-button"
            class="collapse-button"
            :icon="showScanSettingsSection ? 'VasionArrowDropDownIcon' : 'VasionArrowDropDownRight16Icon'"
            title="Collapse Section"
            @vasionButtonClicked="showScanSettingsSection = !showScanSettingsSection"
          />
          <h2 class="subheader">
            Scan Settings
          </h2>
        </div>

        <div v-if="showScanSettingsSection" id="scan-settings-content">
          <div class="row">
            <VasionDropList
              v-slot="slotItem"
              v-model="selectedResolution"
              class="row-item"
              :dataArray="resolutionList"
              :showSearchField="false"
              title="RESOLUTION"
              placeholder="Select Resolution..."
              type="plain-list"
              displayName="name"
              valueName="name"
              @input="setSelectedResolution"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
            <VasionDropList
              v-slot="slotItem"
              v-model="selectedColorMode"
              class="row-item"
              :dataArray="colorModeList"
              :showSearchField="false"
              title="COLOR MODE"
              placeholder="Select Color Mode..."
              type="plain-list"
              displayName="name"
              valueName="name"
              @input="setSelectedColorMode"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
            <VasionDropList
              v-slot="slotItem"
              v-model="selectedScanMode"
              class="row-item"
              :dataArray="scanModeList"
              :showSearchField="false"
              title="SCAN MODE"
              placeholder="Select Scan Mode..."
              type="plain-list"
              displayName="name"
              valueName="name"
              @input="setSelectedScanMode"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
          </div>

          <div class="row">
            <VasionCheckbox
              id="delete-blank-pages-checkbox"
              name="delete-blank-pages-checkbox"
              class="row-item"
              :checked="deleteBlankPages"
              @change="toggleCheckbox('delete-blank-pages')"
            >
              Delete Blank Pages
            </VasionCheckbox>
          </div>
        </div>
      </div>
      <!-- END OF GENERAL TAB -->

      <!-- PERMISSIONS TAB -->
      <div v-if="selectedTabName === 'permissions'" class="main-body">
        <div>
          <VasionListSelection
            :available-fields.sync="availableUsers"
            :selected-fields.sync="assignedUsers"
            :allow-field-ordering="false"
            :available-label-text="'All Users'"
            :selected-label-text="'Permitted Users'"
            :unique-id="'permissions-users'"
            @update:availableFields="markAsDirty"
            @update:selectedFields="markAsDirty"
            @update:addAll="markAsDirty"
            @update:removeAll="markAsDirty"
          />
        </div>
        <div>
          <VasionListSelection
            :available-fields.sync="availableGroups"
            :selected-fields.sync="assignedGroups"
            :allow-field-ordering="false"
            :available-label-text="'All Groups'"
            :selected-label-text="'Permitted Groups'"
            :unique-id="'permissions-groups'"
            @update:availableFields="markAsDirty"
            @update:selectedFields="markAsDirty"
            @update:addAll="markAsDirty"
            @update:removeAll="markAsDirty"
          />
        </div>
      </div>
      <!-- END OF PERMISSIONS TAB -->

      <VasionGeneralModal
        id="browse-modal"
        :rejectButtonText="'CANCEL'"
        :confirmButtonText="'CONFIRM'"
        :modalType="'slot'"
        :sync="showBrowseFoldersDialog"
        @confirmButtonClick="folderDialogOK"
        @noButtonClick="showBrowseFoldersDialog = false"
      >
        <div id="browse-folders-container">
          <VasionFolders
            :onlyShowStorageConfigId="0"
            @itemSelected="folderSelected = true"
          />
        </div>
      </VasionGeneralModal>

      <VasionGeneralModal
        id="isdirty-modal"
        :hideButtons="true"
        :modalType="'slot'"
        buttonGroup="single"
        :sync="showLeaveDialog"
        @noButtonClick="showLeaveDialog = false"
      >
        <VasionConfirmationDialog
          message="Are you sure you want to leave? Changes have not been saved."
          @noButtonClick="showLeaveDialog = false"
          @yesButtonClick="doLeave()"
        />
      </VasionGeneralModal>

      <VasionSnackbar
        id="capture-profile-snack"
        :showSnackbarBool.sync="showSnackbarBool"
        :snackbarImage="snackbarImage"
        :snackbarSubTitle="snackbarSubTitle"
        :snackbarTitle="snackbarTitle"
      />
    </div>
  </div>
</template>

<script>
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'
import Loading from 'vue-loading-overlay';

export default {
  name: 'EditCaptureProfile',
  components: {
    Loading,
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty) {
      this.routeTo = to
      this.showLeaveDialog = true
    } else {
      this.routeTo = null
      next()
    }
  },
  data() {
    return {
      assignedGroups: [],
      assignedUsers: [],
      availableGroups: [],
      availableUsers: [],
      captureProfileId: 0,
      colorModeList: [
        { name: '' },
        { name: 'Black and White' },
        { name: 'Color' },
        { name: 'Grey Scale' },
      ],
      deleteBlankPages: false,
      destinationFolderNeedsInput: false,
      fileBrowseField: '',
      folderSelected: false,
      isDirty: false,
      isLoading: false,
      loaderBackgroundColor,
      loaderColor,
      objectList: [],
      profileName: '',
      profileNameNeedsInput: false,
      promptForFillingOutFields: false,
      resolutionList: [
        { name: '' },
        { name: '100' },
        { name: '200' },
        { name: '300' },
        { name: '400' },
        { name: '500' },
        { name: '600' },
      ],
      savedOrCreated: '',
      scanModeList: [
        { name: '' },
        { name: 'Simplex' },
        { name: 'Duplex' },
      ],
      selectedColorMode: '',
      selectedDestinationFolder: '',
      selectedObject: '',
      selectedResolution: '',
      selectedScanMode: '',
      selectedTabName: '',
      selectedWorkflow: '',
      showBrowseFoldersDialog: false,
      showLeaveDialog: false,
      showScanSettingsSection: false,
      showSnackbarBool: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
      tabSectionNeedsInput: false,
      workflowList: [],
      workflowNeedsInput: false,
    }
  },
  computed: {
    groups() { return this.$store.state.common.groups },
    selectedFolder() { return this.$store.state.common.selectedFolder },
    users() { return this.$store.state.common.users },
    workflowListValues() {
      return this.workflowList.map(w => {
        return {
          name: w.sName,
          value: w.iID,
        }
      })
    },
  },
  async created() {
    this.isLoading = true

    await Promise.all([
      this.$store.dispatch('common/getUsers'),
      this.$store.dispatch('common/getGroups'),
    ])
    this.loadUsersAndGroups()
    this.updateSelectedTab('general')
    const promises = await Promise.all([
      this.$store.dispatch('workflow/getWorkflowMenuItems'),
      this.$store.dispatch('attributeForm/getForms'),
    ])
    // eslint-disable-next-line prefer-destructuring
    this.workflowList = promises[0]
    // eslint-disable-next-line prefer-destructuring
    this.objectList = promises[1]

    this.workflowList.unshift({
      ResultMessage: null,
      ResultStatus: 1,
      iID: 0,
      sName: '',
    })
    this.objectList.unshift({
      name: '',
      value: 0,
    })

    this.captureProfileId = await this.$route.params.captureProfileId
    if (!this.captureProfileId) this.captureProfileId = 0
    if (this.captureProfileId > 0) {
      await this.loadProfileDetails()
    }
    this.savedOrCreated = this.captureProfileId <= 0 ? 'created' : 'saved'

    this.isLoading = false
  },
  methods: {
    calculatedClass(selectedTab) { return this.selectedTabName === selectedTab ? 'text-selected' : 'text' },
    cancel() {
      if (this.isDirty) {
        this.routeTo = null
        this.showLeaveDialog = true
      } else {
        this.$router.push({ name: 'CaptureProfiles' })
      }
    },
    doLeave() {
      this.isDirty = false
      this.showLeaveDialog = false
      if (this.routeTo) {
        this.$router.push({ path: this.routeTo.path })
      } else {
        this.$router.push({ name: 'CaptureProfiles' })
      }
    },
    folderDialogOK() {
      if (this.selectedFolder && this.selectedFolder.item.FolderId > 0 && this.selectedFolder.isSelected === true) {
        if (this.fileBrowseField === 'destination-folder') {
          this.selectedDestinationFolder = { name: this.selectedFolder.item.Name, id: this.selectedFolder.item.FolderId }
        }
        this.markAsDirty()
      } else {
        this.selectedProcessFolderId = null
        this.selectedProcessFolder = ''
      }

      this.showBrowseFoldersDialog = false
      this.removeErrorState('folder-and-workflow')
      if (!this.profileNameNeedsInput) {
        this.removeErrorState('general-tab')
      }
      this.$store.dispatch('common/setSelectedFolder', null)
    },
    async loadProfileDetails() {
      const response = await this.$store.dispatch('admin/getCaptureProfile', this.captureProfileId)

      this.assignedGroups = response.accessGroupIDs.map(e => this.availableGroups.find(x => x.value === e))
      this.assignedUsers = response.accessUserIDs.map(e => this.availableUsers.find(x => x.value === e))
      this.availableGroups = this.availableGroups.filter(x => !this.assignedGroups.includes(x))
      this.availableUsers = this.availableUsers.filter(x => !this.assignedUsers.includes(x))
      this.selectedColorMode = { name: response.colorMode }
      this.deleteBlankPages = response.deleteBlankPages
      this.selectedDestinationFolder = { name: response.folderName, id: response.folderID }
      this.profileName = response.name
      this.promptForFillingOutFields = response.promptForFields
      this.selectedResolution = { name: response.resolution.slice(0, 3) }
      this.selectedScanMode = { name: response.scanMode }

      this.objectList.forEach(obj => {
        if (obj.value === response.objectID) {
          this.selectedObject = obj
        }
      })
      this.workflowList.forEach(workflow => {
        if (workflow.iID === response.workflowID) {
          this.selectedWorkflow = { name: workflow.sName, value: workflow.iID }
        }
      })
    },
    loadUsersAndGroups() {
      this.availableUsers = this.users.map((user) => {
        return {
          name: user.name,
          value: user.value,
        }
      })
      this.availableGroups = this.groups.map((group) => {
        return {
          name: group.name,
          value: group.value,
        }
      })
    },
    markAsDirty() { this.isDirty = true },
    removeErrorState(error) {
      switch (error) {
        case 'general-tab':
          this.tabSectionNeedsInput = false
          break
        case 'profile-name':
          this.profileNameNeedsInput = false
          if (!this.destinationFolderNeedsInput || !this.workflowNeedsInput) {
            this.removeErrorState('general-tab')
          }
          this.markAsDirty()
          break
        case 'folder-and-workflow':
          this.destinationFolderNeedsInput = false
          this.workflowNeedsInput = false
          this.markAsDirty()
          break
        default:
          console.warn(`Unknown error state: ${error}`)
          break
      }
    },
    async save() {
      // START OF: Required Inputs Validation
      let snackbarErrorSubtitle = ''
      if (!this.profileName || !this.profileName.length > 0) {
        this.tabSectionNeedsInput = true
        this.profileNameNeedsInput = true
        snackbarErrorSubtitle += '- Profile Name\n'
      }
      if (this.profileName.trim() === '') {
        this.profileNameNeedsInput = true
        snackbarErrorSubtitle += '- Profile Name cannot only include spaces.\n'
      }

      if ((this.selectedDestinationFolder.id && this.selectedDestinationFolder.id > 0) || this.selectedWorkflow) {
        this.removeErrorState('folder-and-workflow')
      } else {
        snackbarErrorSubtitle += '- Destination Folder OR Workflow'
        this.tabSectionNeedsInput = true
        if (!this.selectedDestinationFolder.id || this.selectedDestinationFolder.id <= 0) {
          this.destinationFolderNeedsInput = true
        }
        if (!this.selectedWorkflow && !this.selectedWorkflow.length > 0) {
          this.workflowNeedsInput = true
        }
      }
      // END OF: Required Inputs Validation

      if (!this.profileNameNeedsInput && (!this.destinationFolderNeedsInput || !this.workflowNeedsInput)) {
        const payload = {
          captureProfileID: this.captureProfileId,
          name: this.profileName,
          folderID: this.selectedDestinationFolder.id ? this.selectedDestinationFolder.id : null,
          workflowID: this.selectedWorkflow.value ? this.selectedWorkflow.value : null,
          objectID: this.selectedObject.value ? this.selectedObject.value : null,
          promptForFields: this.promptForFillingOutFields,
          resolution: this.selectedResolution.name ? `${this.selectedResolution.name}x${this.selectedResolution.name}` : '',
          colorMode: this.selectedColorMode.name ? this.selectedColorMode.name : '',
          scanMode: this.selectedScanMode.name ? this.selectedScanMode.name : '',
          deleteBlankPages: this.deleteBlankPages,
          accessUserIDs: this.assignedUsers.map(e => e.value).filter(e => e != null),
          accessGroupIDs: this.assignedGroups.map(e => e.value).filter(e => e != null),
        }
        const saveSuccess = await this.$store.dispatch('admin/saveCaptureProfile', payload)

        this.snackbarTitle = saveSuccess.Value === 'True' ? 'Success!' : 'Error Saving'
        this.snackbarSubTitle = saveSuccess.Value === 'True'
          ? `Capture Profile successfully ${this.savedOrCreated}.`
          : 'Capture Profile did not save successfully.  Please contact your Administrator if the problem persists.'
        this.snackbarImage = saveSuccess.Value === 'True'

        if (saveSuccess.Value === 'True') {
          this.isDirty = false
          setTimeout(() => {
            this.$router.push({ name: 'CaptureProfiles' })
          }, 1500)
        }
      } else {
        this.snackbarTitle = 'Missing Required Inputs'
        this.snackbarSubTitle = snackbarErrorSubtitle
        this.snackbarImage = false
      }
      this.showSnackbarBool = true
    },
    setSelectedColorMode(e) {
      this.selectedColorMode = e
      this.markAsDirty()
    },
    setSelectedObject(e) {
      this.selectedObject = e
      this.markAsDirty()
    },
    setSelectedResolution(e) {
      this.selectedResolution = e
      this.markAsDirty()
    },
    setSelectedScanMode(e) {
      this.selectedScanMode = e
      this.markAsDirty()
    },
    setSelectedWorkflow(e) {
      this.selectedWorkflow = e
      this.removeErrorState('folder-and-workflow')
      if (!this.profileNameNeedsInput) {
        this.removeErrorState('general-tab')
      }
    },
    toggleBrowseFolderDialog(field) {
      this.folderSelected = false
      this.showBrowseFoldersDialog = !this.showBrowseFoldersDialog

      this.fileBrowseField = ''
      if (this.showBrowseFoldersDialog && field && field !== '') {
        this.fileBrowseField = field
      }
    },
    toggleCheckbox(checkboxName) {
      switch (checkboxName) {
        case 'prompt-for-filling-out-fields':
          this.promptForFillingOutFields = !this.promptForFillingOutFields
          this.markAsDirty()
          break
        case 'delete-blank-pages':
          this.deleteBlankPages = !this.deleteBlankPages
          this.markAsDirty()
          break
        default:
          console.warn(`No matching checkbox name: ${checkboxName}`)
          break
      }
    },
    updateSelectedTab(newSelectedTab) { this.selectedTabName = newSelectedTab },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .main-body {
    padding: 0px 12px 12px 12px;
  }

  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0px;
  }

  .row-item {
    padding-right: 16px;
    padding-top: 16px;
    width: 392px !important;
  }

  .row-item-browse {
    flex-wrap: nowrap;
    display: flex;
  }

  .browse-button {
    margin-top: 17px;
    padding-right: 16px;
  }

  #browse-folders-container {
    width: 400px;
    height: 500px;
  }

  #scan-settings {
    padding-top: 32px;
    padding-left: 0px;

    .collapse-button {
      background-color: $grey-50;
      border-radius: 8px;
      width: 32px;
      height: 32px;
      margin: 0 16px 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .collapse-button:hover {
      border-radius: 8px;
      background-color: $grey-200;
    }
  }

  .collapsable-section {
    display: flex;
    flex-direction: row;

    .subheader {
      @include SubHeadline;
      display: block;
      float: left;
      margin-top: 5px;
    }
  }
</style>
